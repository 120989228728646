/* eslint-disable no-useless-escape */
import React from "react";

import {
  Alert,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { GrammerTable } from "./GrammerTable";
import { Box } from "@mui/system";

const WordDefinition = ({ showDefinition, theme }) => {
  return (
    <div className="word-definition">
      {showDefinition?.type === "en-be" && (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Alert severity="warning" sx={{ mb: 2 }}>
            Слоўнік у апрацоўцы, памылкі магчымы.
          </Alert>
          <b style={{ color: theme.palette.primary.main }}>
            {showDefinition.word}{" "}
          </b>
          <Typography sx={{ whiteSpace: "break-spaces" }}>
            {showDefinition.definition}
          </Typography>
          {showDefinition?.variants?.map(({ word, definition }) => {
            return (
              <Box key={word}>
                <b style={{ color: theme.palette.primary.main }}>{word} </b>
                <Typography sx={{ whiteSpace: "break-spaces" }}>
                  {definition}
                </Typography>
              </Box>
            );
          })}
        </Typography>
      )}
      {["zh-be", "be-zh"].includes(showDefinition?.type) && (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            {showDefinition.word}{" "}
            <Typography variant="caption">
              {showDefinition.ipa || showDefinition.pinyin}
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            sx={{ whiteSpace: "break-spaces", color: "info.main" }}
          >
            {showDefinition.grammer}
          </Typography>

          <Box sx={{ mt: 1 }}>
            {showDefinition?.variants?.map((definition) => {
              return (
                <Box key={definition}>
                  <Typography
                    sx={{ whiteSpace: "break-spaces" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "• " +
                        definition
                          ?.replaceAll("A52A2A", theme.palette.primary.main)
                          ?.replaceAll("#5B9BD5", theme.palette.info.main)
                          ?.replaceAll("4863A0", theme.palette.info.main)
                          ?.replaceAll("CC33FF", theme.palette.warning.main)
                          ?.replaceAll("831b03", theme.palette.secondary.main)
                          ?.replaceAll("151B54", theme.palette.info.dark)
                          ?.replaceAll("#5DD568", theme.palette.success.light)
                          ?.replaceAll("#A0A0A0", theme.palette.grey[600])
                          ?.replaceAll("#A6A6A6", theme.palette.grey[600])
                          ?.replaceAll(`size="+2"`, "")
                          ?.replaceAll(
                            "&nbsp;&nbsp;&nbsp;&nbsp;",
                            "<div style='margin: 8px 0px'/>"
                          )
                          ?.replaceAll(
                            /href="([^\'\"]+)"/g,
                            `style="color: ${theme.palette.primary.main}"`
                          ),
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Typography>
      )}

      {showDefinition?.type === "phonetic" && (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <b style={{ color: theme.palette.primary.main }}>
            {showDefinition.word}{" "}
          </b>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "8px",
              my: 2,
              boxShadow: "none",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableCell>
                  <b>IPA</b>
                </TableCell>
                <TableCell>
                  <b>Школьная транскрыпцыя</b>
                </TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showDefinition.ipa,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showDefinition.schoolVer,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      )}
      {!["grammer", "en-be", "phonetic"].includes(showDefinition?.type) && (
        <div
          style={{
            width: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: showDefinition?.definition
              ?.replaceAll("A52A2A", theme.palette.primary.main)
              ?.replaceAll("0000A0", theme.palette.info.main)
              ?.replaceAll("4863A0", theme.palette.info.main)
              ?.replaceAll("CC33FF", theme.palette.warning.main)
              ?.replaceAll("831b03", theme.palette.secondary.main)
              ?.replaceAll("151B54", theme.palette.info.dark)
              ?.replaceAll("008000", theme.palette.success.light)
              ?.replaceAll("5f5f5f", theme.palette.grey[600])
              ?.replaceAll("333333", theme.palette.text.primary)
              ?.replaceAll(`size="+2"`, "")
              ?.replaceAll(
                "&nbsp;&nbsp;&nbsp;&nbsp;",
                "<div style='margin: 8px 0px'/>"
              )
              ?.replaceAll(
                /href="([^\'\"]+)"/g,
                `style="color: ${theme.palette.primary.main}"`
              ),
          }}
        />
      )}

      {showDefinition?.type === "grammer" && (
        <>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <b style={{ color: theme.palette.primary.main }}>
              {showDefinition.word}{" "}
            </b>
            <Typography>{showDefinition.grammer}</Typography>
          </Typography>
          {showDefinition?.details?.variants.map(
            ({ forms, dictionaries, id }) => {
              return (
                <Box key={id}>
                  <GrammerTable
                    type={showDefinition?.details?.tag}
                    forms={forms}
                  />

                  <Box sx={{ my: 2, mx: -0.5 }}>
                    {dictionaries?.map((dictionary) => (
                      <Chip
                        label={dictionary}
                        key={dictionary}
                        sx={{ m: 0.5 }}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>
              );
            }
          )}
        </>
      )}
    </div>
  );
};

export default WordDefinition;
