import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import lodash from "lodash";

const NCIINN1TagMapper = {
  S: "адз.",
  P: "мн.",
  NS: "Н.",
  GS: "Р.",
  DS: "Д.",
  AS: "В.",
  IS: "Т.",
  LS: "М.",
  NP: "Н.",
  GP: "Р.",
  DP: "Д.",
  AP: "В.",
  IP: "Т.",
  LP: "М.",
};

export const NCIINN1Render = ({ forms }) => {
  const theme = useTheme();
  const groupped = Object.entries(
    lodash.groupBy(
      forms.map((d) => {
        return {
          ...d,
          groupBy: d.tag[1],
        };
      }),
      "groupBy"
    )
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px",
        my: 2,
        boxShadow: "none",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.23)",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableCell align="left" sx={{ width: "20px" }} rowSpan={6}>
            Лік
          </TableCell>
          <TableCell align="left" sx={{ width: "20px" }}>
            Склон
          </TableCell>
          <TableCell align="left">Форма</TableCell>
        </TableHead>
        <TableBody>
          {groupped.map(([tag, values]) => {
            const groupped = Object.entries(lodash.groupBy(values, "tag"));
            return groupped.map(([key, v], i) => {
              return (
                <TableRow key={i}>
                  {i === 0 && (
                    <TableCell
                      align="left"
                      rowSpan={6}
                      sx={{
                        borderRight: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      {NCIINN1TagMapper[tag]}
                    </TableCell>
                  )}

                  <TableCell align="left">{NCIINN1TagMapper[key]}</TableCell>
                  <TableCell align="left">
                    {v.map((s, id) => {
                      return <p key={id}>{s.value}</p>;
                    })}
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
