import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
} from "@mui/material";
import { Book, FilterAltOutlined } from "@mui/icons-material";
import { Types } from "./WordList";
import { useFilters } from "./context/filters";
import { isMobile } from "react-device-detect";
import Close from "@mui/icons-material/Close";

const BasedOn = {
  "ru-be": "skarnik.by",
  "be-ru": "skarnik.by",
  "en-be": "Т.М. Суша",
  tsbm: "skarnik.by",
  grammer: "bnkorpus.info",
  phonetic: "bnkorpus.info",
  "zh-be": "2021",
  "be-zh": "2021",
};

const defaultFilters = {
  "ru-be": true,
  "be-ru": true,
  "en-be": true,
  tsbm: true,
  grammer: true,
  phonetic: true,
  "zh-be": true,
  "be-zh": true,
};

function Filters({ closeOnOpen }) {
  const defaultState = JSON.parse(
    localStorage.getItem("filters") || JSON.stringify(defaultFilters)
  );

  const { change: setState, ...state } = useFilters();

  const handleChange = (key) => (_, value) => {
    const nextState = {
      ...state,
      [key]: value,
    };

    setState(nextState);
  };

  const isChanged = JSON.stringify(state) !== JSON.stringify(defaultState);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    if (closeOnOpen) {
      closeOnOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen} dence>
        <ListItemIcon>
          <FilterAltOutlined />
        </ListItemIcon>
        <ListItemText>Фільтр слоўнікаў</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={isMobile}
      >
        <DialogTitle id="alert-dialog-title">
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1 }}>
              <Close />
            </IconButton>
          )}
          Фільтр слоўнікаў
        </DialogTitle>
        <DialogContent>
          <List sx={{ m: -1 }}>
            {Object.keys(Types).map((key) => {
              return (
                <ListItem key={key}>
                  <ListItemIcon sx={{ minWidth: 46 }}>
                    <Book />
                  </ListItemIcon>
                  <ListItemText
                    primary={Types[key].toUpperCase()}
                    secondary={BasedOn[key]}
                  />
                  <Switch
                    checked={state[key]}
                    edge="end"
                    sx={{ ml: 1 }}
                    onChange={handleChange(key)}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2,
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
          }}
        >
          <Button
            onClick={() => {
              setTimeout(() => setState(defaultState), 250);
              handleClose();
            }}
            fullWidth={isMobile}
          >
            Закрыць
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("filters", JSON.stringify(state));
              handleClose();
            }}
            autoFocus
            variant="contained"
            disabled={!isChanged}
            fullWidth={isMobile}
            sx={{ mb: isMobile ? 2 : 0 }}
          >
            Захаваць
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Filters;
