import { NCIINN0Render } from "./grammers/NCIINN0Render";
import { NCIINM1Render } from "./grammers/NCIINM1Render";
import { NCIINN2Render } from "./grammers/NCIINN2Render";
import { SNP1Render } from "./grammers/SNP1Render";
import { VTMN1Render } from "./grammers/VTMN1Render";
import { NCIINN1Render } from "./grammers/NCIINN1Render";
import { AQPRender } from "./grammers/AQPRender";
import { RARender } from "./grammers/RARender";
import { WRender } from "./grammers/WRender";
import { MNCSRender } from "./grammers/MNCSRender";

const GrammerTypes = {
  AQC: "AQC",
  AQP: "AQP",
  AQS: "AQS",
  ARP: "ARP",
  CKX: "CKX",
  E: "E",
  I: "I",
  MAKS: "MAKS",
  MNCC: "MNCC",
  MNCS: "MNCS",
  MNKS: "MNKS",
  NCAINF0: "NCAINF0",
  NCAINF2: "NCAINF2",
  NCAINF3: "NCAINF3",
  NCAINM0: "NCAINM0",
  NCAINM1: "NCAINM1",
  NCAINM2: "NCAINM2",
  NCAPBM1: "NCAPBM1",
  NCAPNF2: "NCAPNF2",
  NCAPNF4: "NCAPNF4",
  NCAPNM1: "NCAPNM1",
  NCAPNM2: "NCAPNM2",
  NCAPNM6: "NCAPNM6",
  NCAPNN1: "NCAPNN1",
  NCAPNP7: "NCAPNP7",
  NCAPNS5: "NCAPNS5",
  NCIINF2: "NCIINF2",
  NCIINF3: "NCIINF3",
  NCIINM1: "NCIINM1",
  NCIINN0: "NCIINN0",
  NCIINN1: "NCIINN1",
  NCIINN4: "NCIINN4",
  NCIINP7: "NCIINP7",
  NCIINS5: "NCIINS5",
  NPIINF2: "NPIINF2",
  NPIINF3: "NPIINF3",
  NPIINM1: "NPIINM1",
  NPIINN1: "NPIINN1",
  NPIINP7: "NPIINP7",
  PARM: "PARM",
  PPPM: "PPPM",
  PPPP: "PPPP",
  RA: "RA",
  RX: "RX",
  SAD0: "SAD0",
  SAF0: "SAF0",
  SAS0: "SAS0",
  SNL0: "SNL0",
  SNP1: "SNP1",
  VDMN1: "VDMN1",
  VDMN2: "VDMN2",
  VDMN3: "VDMN3",
  VDPN1: "VDPN1",
  VDPN2: "VDPN2",
  VIMN1: "VIMN1",
  VIMN2: "VIMN2",
  VIMN3: "VIMN3",
  VIMR1: "VIMR1",
  VIMR2: "VIMR2",
  VIPN1: "VIPN1",
  VIPR1: "VIPR1",
  VIPR2: "VIPR2",
  VTMN1: "VTMN1",
  VTMN2: "VTMN2",
  VTPN1: "VTPN1",
  VTPN2: "VTPN2",
  VTPN3: "VTPN3",
  W: "W",
  Y: "Y",
  Z: "Z",
};

const GrammerTypesComponent = {
  [GrammerTypes.AQC]: AQPRender,
  [GrammerTypes.AQP]: AQPRender,
  [GrammerTypes.AQS]: AQPRender,
  [GrammerTypes.ARP]: AQPRender,
  [GrammerTypes.CKX]: WRender,
  [GrammerTypes.E]: WRender,
  [GrammerTypes.I]: WRender,
  [GrammerTypes.MAKS]: AQPRender,
  [GrammerTypes.MNCC]: SNP1Render,
  [GrammerTypes.MNCS]: MNCSRender,
  [GrammerTypes.MNKS]: SNP1Render,
  [GrammerTypes.NCAINF0]: NCIINN0Render,
  [GrammerTypes.NCAINF2]: NCIINN0Render,
  [GrammerTypes.NCAINF3]: NCIINN0Render,
  [GrammerTypes.NCAINM0]: NCIINN0Render,
  [GrammerTypes.NCAINM1]: NCIINN0Render,
  [GrammerTypes.NCAINM2]: NCIINN0Render,
  [GrammerTypes.NCAPBM1]: NCIINN0Render,
  [GrammerTypes.NCAPNF2]: NCIINN2Render,
  [GrammerTypes.NCAPNF4]: NCIINN2Render,
  [GrammerTypes.NCAPNM1]: NCIINN0Render,
  [GrammerTypes.NCAPNM2]: NCIINN0Render,
  [GrammerTypes.NCAPNM6]: NCIINN2Render,
  [GrammerTypes.NCAPNN1]: NCIINN2Render,
  [GrammerTypes.NCAPNP7]: NCIINN0Render,
  [GrammerTypes.NCAPNS5]: AQPRender,
  [GrammerTypes.NCIINF2]: NCIINN2Render,
  [GrammerTypes.NCIINF3]: NCIINN0Render,
  [GrammerTypes.NCIINM1]: NCIINM1Render,
  [GrammerTypes.NCIINN0]: NCIINN0Render,
  [GrammerTypes.NCIINN1]: NCIINN1Render,
  [GrammerTypes.NCIINN4]: NCIINN2Render,
  [GrammerTypes.NCIINP7]: NCIINN0Render,
  [GrammerTypes.NCIINS5]: AQPRender,
  [GrammerTypes.NPIINF2]: NCIINN0Render,
  [GrammerTypes.NPIINF3]: NCIINN0Render,
  [GrammerTypes.NPIINM1]: NCIINN0Render,
  [GrammerTypes.NPIINN1]: NCIINN0Render,
  [GrammerTypes.NPIINP7]: NCIINN0Render,
  [GrammerTypes.PARM]: AQPRender,
  [GrammerTypes.PPPM]: AQPRender,
  [GrammerTypes.PPPP]: AQPRender,
  [GrammerTypes.RA]: RARender,
  [GrammerTypes.RX]: RARender,
  [GrammerTypes.SAD0]: AQPRender,
  [GrammerTypes.SAF0]: AQPRender,
  [GrammerTypes.SAS0]: AQPRender,
  [GrammerTypes.SNL0]: SNP1Render,
  [GrammerTypes.SNP1]: SNP1Render,
  [GrammerTypes.VDMN1]: VTMN1Render,
  [GrammerTypes.VDMN2]: VTMN1Render,
  [GrammerTypes.VDMN3]: VTMN1Render,
  [GrammerTypes.VDPN1]: VTMN1Render,
  [GrammerTypes.VDPN2]: VTMN1Render,
  [GrammerTypes.VIMN1]: VTMN1Render,
  [GrammerTypes.VIMN2]: VTMN1Render,
  [GrammerTypes.VIMN3]: VTMN1Render,
  [GrammerTypes.VIMR1]: VTMN1Render,
  [GrammerTypes.VIMR2]: VTMN1Render,
  [GrammerTypes.VIPN1]: VTMN1Render,
  [GrammerTypes.VIPR1]: VTMN1Render,
  [GrammerTypes.VIPR2]: VTMN1Render,
  [GrammerTypes.VTMN1]: VTMN1Render,
  [GrammerTypes.VTMN2]: VTMN1Render,
  [GrammerTypes.VTPN1]: VTMN1Render,
  [GrammerTypes.VTPN2]: VTMN1Render,
  [GrammerTypes.VTPN3]: VTMN1Render,
  [GrammerTypes.W]: WRender,
  [GrammerTypes.Y]: WRender,
};

export const GrammerTable = ({ type, forms }) => {
  if (!GrammerTypes[type]) {
    return null;
  }

  const Component = GrammerTypesComponent[type];
  if (!Component) {
    return null;
  }

  return <Component forms={forms} />;
};
