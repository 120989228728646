import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useFilters } from "./context/filters";
import { useSearchParams } from "react-router-dom";
import WordDefinition from "./WordDefinition";
import { useSettings } from "./context/settings";

export const Types = {
  "ru-be": "Руска-беларускі",
  "be-ru": "Беларуска-рускі",
  "en-be": "англійска-беларускі",
  tsbm: "ТЛУМАЧАЛЬНЫ",
  grammer: "ГРАМАТЫЧНЫ",
  phonetic: "АРФАЭПІЧНЫ",
  "zh-be": "Кітайска-беларускі",
  "be-zh": "Беларуска-кітайскі",
};

const DETAILS_CHECKS = ["ru-be", "be-ru"];

const WordList = ({ words, setShowDefinition }) => {
  const filters = useFilters();
  const [params, setParams] = useSearchParams();
  const theme = useTheme();
  const query = params.get("q");

  const { showDetails } = useSettings();

  return (
    <List sx={{ width: "100%" }} dence>
      {words
        ?.filter(({ type }) => {
          if (filters[type] === false) {
            return false;
          }

          return true;
        })
        ?.sort((a, b) => {
          if (!showDetails) {
            return 0;
          }

          const aCheck =
            a.word?.toLowerCase() === query?.toLowerCase() &&
            DETAILS_CHECKS.includes(a.type);

          if (aCheck) {
            return -1;
          }

          return 0;
        })
        ?.map((d, i) => {
          const { id, word, type, definition } = d;
          const isTarget = showDetails
            ? word?.toLowerCase() === query?.toLowerCase() &&
              DETAILS_CHECKS.includes(type)
            : false;

          return (
            <ListItem
              key={i.toString() + word}
              button
              onClick={() => {
                setShowDefinition(d);
                setParams({
                  q: query,
                  wordId: id,
                });
              }}
              sx={{
                borderRadius: 1,
                alignItems: "center",
                paddingTop: "6px!important",
                border: isTarget ? `1px solid ${theme.palette.divider}` : "",

                marginBottom: isTarget ? "12px" : "",
              }}
            >
              <ListItemText
                primary={<>{word} </>}
                secondary={
                  type === "grammer" && definition ? (
                    <div>
                      {type === "grammer" && (
                        <Typography
                          variant="caption"
                          sx={{ color: "primary.main" }}
                        >
                          {definition}
                        </Typography>
                      )}
                      <br />

                      <>{Types[type]}</>
                    </div>
                  ) : (
                    <Box sx={{ textTransform: "math-auto" }}>
                      {isTarget && (
                        <>
                          <WordDefinition showDefinition={d} theme={theme} />
                          <br />
                        </>
                      )}
                      {Types[type]}
                    </Box>
                  )
                }
                primaryTypographyProps={{
                  variant: "h5",
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  sx: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </ListItem>
          );
        })}
    </List>
  );
};

export default WordList;
