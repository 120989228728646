import "abortcontroller-polyfill";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  capitalize,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  NewspaperRounded,
  SearchOutlined,
  ShareRounded,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useWindowSize } from "./hooks/useWindowSize";
import { useTheme } from "@mui/system";
import { AppMenu } from "./AppMenu";
import WordList, { Types } from "./WordList";
import WordDefinition from "./WordDefinition";
import { useSettings } from "./context/settings";
import { useSearchParams } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Share } from "@capacitor/share";

const API_URL = "https://dictionaries-api.vivy.app/search";

export default function Application() {
  const { width } = useWindowSize();
  const [params, setParams] = useSearchParams();
  const settings = useSettings();
  const theme = useTheme();
  const inputRef = useRef();
  const [search, setSearch] = useState(params.get("q") || "");
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [showDefinition, setShowDefinition] = useState(null);

  useEffect(() => {
    if (Capacitor.getPlatform() !== "android") {
      return;
    }

    App.addListener("backButton", () => {
      if (showDefinition) {
        setShowDefinition(null);
      } else {
        App.exitApp();
      }
    });

    return () => {
      App.removeAllListeners();
    };
  });

  const handleChange = useCallback(
    async (e) => {
      const {
        target: { value },
      } = e;

      setSearch(value);

      setParams(
        {
          q: value,
        },
        { replace: true }
      );

      if (result) {
        setResult(null);
      }

      if (showDefinition) {
        setShowDefinition(null);
      }
    },
    [result, showDefinition, setParams]
  );

  const handleSearch = useCallback(async () => {
    if (search) {
      const { signal, abort } = new window.AbortController();
      setLoading(true);

      const data = await fetch(
        `${API_URL}?q=${search.toLocaleLowerCase()}&limit=8`,
        { signal }
      ).then((d) => d.json());
      if (data?.error) {
        return setLoading(false);
      }
      setResult(() => data);
      setLoading(false);

      const wordId = params.get("wordId");
      if (wordId) {
        const entity = data.find(({ id }) => String(id) === String(wordId));

        if (entity) {
          setShowDefinition(entity);
        }
      }

      return () => {
        abort();
      };
    }
  }, [search, params, setShowDefinition]);

  useEffect(() => {
    if (settings?.autoSearch === false) {
      return;
    }

    const delayDebounceFn = setTimeout(handleSearch, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearch, settings]);

  const changeStyles = width > 650;

  return (
    <Container maxWidth="sm" sx={{ position: "relative" }}>
      {params?.embed !== "1" && (
        <>
          <Box
            sx={{
              position: "absolute",
              right: !changeStyles ? 42 : 62,
              top: !changeStyles ? 8 : 16,
              height: "40px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Link
              href="https://boosty.to/vivy"
              target="_blank"
              underline="none"
              sx={{
                height: "40px",
              }}
            >
              <Button
                sx={{
                  borderRadius: 2,
                  px: 2,
                  height: "40px",
                }}
                startIcon={<NewspaperRounded />}
              >
                Блог VIVY
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: !changeStyles ? 8 : 16,
              top: !changeStyles ? 8 : 16,
            }}
          >
            <AppMenu />
          </Box>
        </>
      )}
      <Box
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          height: Capacitor.isNativePlatform() ? "calc(100vh - 24px)" : "100vh",
          flexDirection: "column",
          pt: 4,
        }}
      >
        {params?.embed !== "1" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              pt: 4,
              mb: !changeStyles ? 4 : 0,
            }}
          >
            <img src="/logo.svg" alt="logo" width={!changeStyles ? 40 : 80} />
            <Typography variant="h4" sx={{ ml: 2 }}>
              Слоўнікі
            </Typography>
          </Box>
        )}
        {changeStyles && (
          <Typography variant="body1" sx={{ my: 4, textAlign: "center" }}>
            База складае руска-беларускі, беларуска-рускі, граматычны і
            тлумачальны слоўнікі. Агульная колькасць слоў больш за <b>500</b>{" "}
            тыс.
          </Typography>
        )}

        <TextField
          placeholder="Пошук слоў"
          fullWidth
          value={search}
          autoCapitalize="none"
          autoCorrect="none"
          autoSave="none"
          autoFocus
          autoComplete="new-password"
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          inputRef={inputRef}
          InputProps={{
            sx: { borderRadius: 50 },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={!isLoading ? handleSearch : undefined}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SearchOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {settings?.keyboardHelpers !== false && (
                  <>
                    <IconButton
                      onClick={() => {
                        setSearch(search + "і");

                        if (inputRef?.current?.focus) {
                          inputRef?.current?.focus();
                        }
                      }}
                    >
                      <Typography sx={{ width: 24 }}>І</Typography>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSearch(search + "ў");
                        if (inputRef?.current?.focus) {
                          inputRef?.current?.focus();
                        }
                      }}
                    >
                      <Typography sx={{ width: 24 }}>Ў</Typography>
                    </IconButton>
                  </>
                )}

                {!!search && (
                  <IconButton
                    onClick={() => {
                      setSearch("");
                      setShowDefinition(false);
                      setResult(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {showDefinition ? (
          <Box sx={{ mt: 2, width: "100%", mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                pb: 2,
                width: "100%",
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => setShowDefinition(null)}
                sx={{ borderRadius: 2, px: 2 }}
              >
                Назад
              </Button>

              <Button
                endIcon={<ShareRounded />}
                sx={{ borderRadius: 2, px: 2 }}
                onClick={async () => {
                  const title = `Слоўнікі: ${capitalize(
                    showDefinition.word
                  )} - ${Types[
                    showDefinition.type
                  ]?.toLocaleLowerCase()} слоўнік`;

                  if (Capacitor.isNativePlatform()) {
                    await Share.share({
                      title: showDefinition.word,
                      text: title,
                      url: window.location.href,
                      dialogTitle: showDefinition.word,
                    });
                  } else {
                    navigator.share({
                      url: window.location.href,
                      title,
                    });
                  }
                }}
              >
                Абагуліць
              </Button>
            </Box>
            <WordDefinition showDefinition={showDefinition} theme={theme} />
          </Box>
        ) : (
          <>
            {isLoading && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && !!search && result && !result?.length && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <SentimentDissatisfiedIcon sx={{ fontSize: 64, mb: 1 }} />
                <Typography variant="h6">Нічога не знойдзена</Typography>
              </Box>
            )}
            <Box
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 1,
                mb: 2,
              }}
            >
              {!isLoading && search && (
                <WordList
                  words={result}
                  setShowDefinition={setShowDefinition}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}
