import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import lodash from "lodash";

export const AQPTagMapper = {
  0: "м.",
  1: "н.",
  2: "ж.",
  FAP: "В.",
  FAS: "В.",
  FDP: "Д.",
  FDS: "Д.",
  FGP: "Р.",
  FGS: "Р.",
  FIP: "Т.",
  FIS: "Т.",
  FLP: "М.",
  FLS: "М.",
  FNP: "Н.",
  FNS: "Н.",
  PAP: "В.",
  PAS: "В.",
  PDP: "Д.",
  PDS: "Д.",
  PGP: "Р.",
  PGS: "Р.",
  PIP: "Т.",
  PIS: "Т.",
  PLP: "М.",
  PLS: "М.",
  PNP: "Н.",
  PNS: "Н.",
  I2P: "2-я ас., мн.",
  I2S: "2-я ас., адз.",
  many: "мн.",
  MAP: "В.",
  MAS: "В.",
  MDP: "Д.",
  MDS: "Д.",
  MGP: "Р.",
  MGS: "Р.",
  MIP: "Т.",
  MIS: "Т.",
  MLP: "М.",
  MLS: "М.",
  MNP: "Н.",
  MNS: "Н.",
  "0AP": "В.",
  "0AS": "В.",
  "0DP": "Д.",
  "0DS": "Д.",
  "0GP": "Р.",
  "0GS": "Р.",
  "0IP": "Т.",
  "0IS": "Т.",
  "0LP": "М.",
  "0LS": "М.",
  "0NP": "Н.",
  "0NS": "Н.",
  NAP: "В.",
  NAS: "В.",
  NDP: "Д.",
  NDS: "Д.",
  NGP: "Р.",
  NGS: "Р.",
  NIP: "Т.",
  NIS: "Т.",
  NLP: "М.",
  NLS: "М.",
  NNP: "Н.",
  NNS: "Н.",
  one: "адз.",
  PFS: "ж., адз.",
  PMS: "м., адз.",
  PXP: "мн.",
  RG: "цяп. час",
  INANIM: "неадуш.",
  ANIM: "адуш.",
};

const convert = ({ acc, key, arr, check }) => {
  const list = arr.filter(check);
  acc[key] = lodash.groupBy(list, "tag");

  return acc;
};

export const AQPRender = ({ forms }) => {
  const theme = useTheme();
  const groupped = forms.reduce((acc, { tag, value }, _, arr) => {
    if (tag === "0") {
      acc["0"] = value;
    }

    if (tag.startsWith("M") && tag.endsWith("S")) {
      acc = convert({
        acc,
        arr,
        key: "ms",
        check: ({ tag }) => tag.startsWith("M") && tag.endsWith("S"),
      });
    }

    if (tag.startsWith("N") && tag.endsWith("S")) {
      acc = convert({
        acc,
        arr,
        key: "ns",
        check: ({ tag }) => tag.startsWith("N") && tag.endsWith("S"),
      });
    }

    if (tag.startsWith("F") && tag.endsWith("S")) {
      acc = convert({
        acc,
        arr,
        key: "fs",
        check: ({ tag }) => tag.startsWith("F") && tag.endsWith("S"),
      });
    }

    if (tag.startsWith("P") && tag.endsWith("P")) {
      acc = convert({
        acc,
        arr,
        key: "p",
        check: ({ tag }) => tag.startsWith("P") && tag.endsWith("P"),
      });
    }

    if (tag.startsWith("0") && tag.endsWith("P")) {
      acc = convert({
        acc,
        arr,
        key: "0",
        check: ({ tag }) => tag.startsWith("0") && tag.endsWith("P"),
      });
    }

    return acc;
  }, {});

  if (!groupped) {
    return null;
  }

  const target = {
    one: [groupped.ms, groupped.ns, groupped.fs],
    many: [groupped["0"], groupped.p],
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "8px",
          my: 2,
          boxShadow: "none",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.23)",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableCell align="left" sx={{ width: "20px" }} rowSpan={6}>
              <b>Лік</b>
            </TableCell>
            <TableCell align="left" sx={{ width: "20px" }}>
              <b>Род</b>
            </TableCell>
            <TableCell align="left" sx={{ width: "20px" }}>
              <b>Склон</b>
            </TableCell>
            <TableCell align="left" colSpan={4}>
              <b>Форма</b>
            </TableCell>
          </TableHead>
          <TableBody>
            {Object.entries(target).map(([key, values], indexDefault) => {
              const target = values.filter(Boolean);
              let rowsLength = 0;

              for (const iterator of target) {
                if (typeof iterator === "object") {
                  const keys = Object.keys(iterator);
                  for (const keyId of keys) {
                    if (iterator[keyId]) {
                      rowsLength = rowsLength + 1;
                    }
                  }
                }
              }

              return (
                <>
                  {target.map((value, i) => {
                    return Object.entries(value).map(([k, v], index, arr) => {
                      return (
                        <TableRow>
                          {[0, 1].includes(indexDefault) &&
                            index === 0 &&
                            i === 0 && (
                              <TableCell
                                rowSpan={rowsLength}
                                sx={{
                                  borderRight: `1px solid ${theme.palette.divider}`,
                                }}
                              >
                                {AQPTagMapper[key]}
                              </TableCell>
                            )}

                          {index === 0 && (
                            <TableCell
                              rowSpan={arr.length}
                              sx={{
                                borderRight: `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              {key !== "many" && AQPTagMapper[i]}
                            </TableCell>
                          )}

                          <TableCell>{AQPTagMapper[k]}</TableCell>
                          <TableCell>
                            {v.map(({ value, options }) => (
                              <>
                                <p
                                  style={{ display: "flex", whiteSpace: "pre" }}
                                >
                                  {value}
                                  {options && (
                                    <Typography
                                      variant="body2"
                                      color="info.main"
                                    >
                                      {" "}
                                      ({AQPTagMapper[options] || options})
                                    </Typography>
                                  )}
                                </p>
                              </>
                            ))}
                          </TableCell>
                        </TableRow>
                      );
                    });
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
