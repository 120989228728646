import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import lodash from "lodash";

const RATagMapper = {
  P: "станоўч.",
  C: "выш.",
  S: "найвыш.",
};

export const RARender = ({ forms }) => {
  const groupped = Object.entries(lodash.groupBy(forms, "tag"));

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px",
        my: 2,
        boxShadow: "none",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.23)",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableCell align="left">Ступень параўнання</TableCell>
          <TableCell align="left">Форма</TableCell>
        </TableHead>
        <TableBody>
          {groupped.map(([tag, values]) => {
            return (
              <TableRow>
                <TableCell align="left">{RATagMapper[tag]}</TableCell>
                <TableCell align="left">
                  {values.map(({ value }) => (
                    <p key={value}>{value || "–"}</p>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
