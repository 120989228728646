import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import lodash from "lodash";

const VTMN1TagMapper = {
  "0NS": "Н.",
  "0GS": "Р.",
  "0DS": "Д.",
  "0AS": "В.",
  "0IS": "Т.",
  "0LS": "М.",
  I2S: "2-я ас., адз.",
  I2P: "2-я ас., мн.",
  PMS: "м., адз.",
  PFS: "ж., адз.",
  PXP: "мн.",
  PNS: "н., адз.",
  RG: "цяп. час",
  FAP: "В.",
  FAS: "В.",
  FDP: "Д.",
  FDS: "Д.",
  FGP: "Р.",
  FGS: "Р.",
  FIP: "Т.",
  FIS: "Т.",
  FLP: "М.",
  FLS: "М.",
  FNP: "Н.",
  FNS: "Н.",
  PAP: "В.",
  PAS: "В.",
  PDP: "Д.",
  PDS: "Д.",
  PGP: "Р.",
  PGS: "Р.",
  PIP: "Т.",
  PIS: "Т.",
  PLP: "М.",
  PLS: "М.",
  PNP: "Н.",
  PG: "прош. час",
  many: "мн.",
  MAP: "В.",
  MAS: "В.",
  MDP: "Д.",
  MDS: "Д.",
  MGP: "Р.",
  MGS: "Р.",
  MIP: "Т.",
  MIS: "Т.",
  MLP: "М.",
  MLS: "М.",
  MNP: "Н.",
  MNS: "Н.",
  NAP: "В.",
  NAS: "В.",
  NDP: "Д.",
  NDS: "Д.",
  NGP: "Р.",
  NGS: "Р.",
  NIP: "Т.",
  NIS: "Т.",
  NLP: "М.",
  NLS: "М.",
  NNP: "Н.",
  NNS: "Н.",
  one: "адз.",

  INANIM: "неадуш.",
  ANIM: "адуш.",
  1: "1-я ас.",
  2: "2-я ас.",
  3: "3-я ас.",
};

const convert = ({ acc, key, arr, check }) => {
  const list = arr.filter(check);
  acc[key] = lodash.groupBy(list, "tag");

  return acc;
};

export const VTMN1Render = ({ forms }) => {
  const groupped = forms.reduce((acc, { tag, value }, _, arr) => {
    if (tag === "0") {
      acc["0"] = value;
    }

    if (tag.includes("S") && tag.includes("R")) {
      acc = convert({
        acc,
        arr,
        key: "rs",
        check: ({ tag }) => tag.includes("S") && tag.includes("R"),
      });
    }

    if (tag.includes("P") && tag.includes("R")) {
      acc = convert({
        acc,
        arr,
        key: "rp",
        check: ({ tag }) => tag.includes("P") && tag.includes("R"),
      });
    }

    if (tag.includes("F") && tag.includes("S")) {
      acc = convert({
        acc,
        arr,
        key: "fs",
        check: ({ tag }) => tag.includes("F") && tag.includes("S"),
      });
    }

    if (tag.includes("F") && tag.includes("P")) {
      acc = convert({
        acc,
        arr,
        key: "fp",
        check: ({ tag }) => tag.includes("F") && tag.includes("P"),
      });
    }

    if ((tag.includes("P") && tag.includes("S")) || tag === "PXP") {
      acc = convert({
        acc,
        arr,
        key: "ps",
        check: ({ tag }) =>
          (tag.includes("P") && tag.includes("S")) || tag === "PXP",
      });
    }

    if (tag.includes("I")) {
      acc = convert({
        acc,
        arr,
        key: "i",
        check: ({ tag }) => tag.includes("I"),
      });
    }

    if (tag.includes("G")) {
      acc = convert({
        acc,
        arr,
        key: "g",
        check: ({ tag }) => tag.includes("G"),
      });
    }

    return acc;
  }, {});

  if (!groupped) {
    return null;
  }

  const presentValues = {
    1:
      groupped?.rs?.["R1S"] && groupped?.rp?.["R1P"]
        ? [groupped?.rs?.["R1S"], groupped?.rp?.["R1P"]]
        : null,
    2:
      groupped?.rs?.["R2S"] && groupped?.rp?.["R2P"]
        ? [groupped?.rs?.["R2S"], groupped?.rp?.["R2P"]]
        : null,
    3:
      groupped?.rs?.["R3S"] && groupped?.rp?.["R3P"]
        ? [groupped?.rs?.["R3S"], groupped?.rp?.["R3P"]]
        : null,
  };

  const futureValues = {
    1:
      groupped?.fs?.["F1S"] && groupped?.fp?.["F1P"]
        ? [groupped?.fs?.["F1S"], groupped?.fp?.["F1P"]]
        : null,
    2:
      groupped?.fs?.["F2S"] && groupped?.fp?.["F2P"]
        ? [groupped?.fs?.["F2S"], groupped?.fp?.["F2P"]]
        : null,
    3:
      groupped?.fs?.["F3S"] && groupped?.fp?.["F3P"]
        ? [groupped?.fs?.["F3S"], groupped?.fp?.["F3P"]]
        : null,
  };

  const target = {
    default: {
      name: "Пачатковая форма",
      values: {
        default: groupped["0"],
      },
    },
    future: {
      name: "Будучы час",
      values: Object.entries(futureValues)
        .filter(Boolean)
        .some(([_, v]) => !!v)
        ? futureValues
        : undefined,
      options: ["адз.", "мн."],
    },
    present: {
      name: "Цяперашні час",
      values: Object.entries(presentValues)
        .filter(Boolean)
        .some(([_, v]) => !!v)
        ? presentValues
        : undefined,
      options: ["адз.", "мн."],
    },
    past: {
      name: "Прошлы час",
      values: groupped.ps,
    },
    i: {
      name: "Загадны лад",
      values: groupped.i,
    },
    rg: {
      name: "Дзеепрыслоўе",
      values: groupped.g,
    },
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "8px",
          my: 2,
          boxShadow: "none",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.23)",
        }}
      >
        <Table size="small">
          <TableBody>
            {Object.entries(target).map(([key, { name, values, options }]) => {
              if (lodash.isEmpty(values)) {
                return null;
              }

              return (
                <>
                  <TableRow key={key}>
                    <TableCell
                      align="left"
                      colSpan={options ? options?.length - 1 : 4}
                    >
                      <b>{name}</b>
                    </TableCell>
                    {options?.map((option) => (
                      <TableCell align="left">
                        <b>{option}</b>
                      </TableCell>
                    ))}
                  </TableRow>

                  {Object.entries(values).map(([k, v]) => {
                    if (key === "default") {
                      if (!v) {
                        return null;
                      }

                      return (
                        <TableRow key={v}>
                          <TableCell align="left" colSpan={4}>
                            {v}
                          </TableCell>
                        </TableRow>
                      );
                    }

                    if (key === "present") {
                      if (!v) {
                        return null;
                      }

                      return (
                        <TableRow key={k}>
                          <TableCell align="left">
                            {VTMN1TagMapper[k] || k}
                          </TableCell>
                          {v?.map((value) => {
                            return (
                              <TableCell align="left">
                                {value.map((n) => (
                                  <p>{n.value}</p>
                                ))}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }

                    if (key === "future") {
                      if (!v) {
                        return null;
                      }

                      return (
                        <TableRow key={k}>
                          <TableCell align="left">
                            {VTMN1TagMapper[k] || k}
                          </TableCell>
                          {v?.map((value) => {
                            return (
                              <TableCell align="left">
                                {value.map((n) => (
                                  <p>{n.value}</p>
                                ))}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }

                    return (
                      <TableRow key={k}>
                        <TableCell align="left">
                          {VTMN1TagMapper[k] || k}
                        </TableCell>
                        {Object.entries(v).map(([_, { value }], i, arr) => {
                          return (
                            <TableCell
                              align="left"
                              colSpan={arr.length - 1 === i ? 3 : undefined}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
