import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { AQPTagMapper } from "./AQPRender";

const SNP1TagMapper = {
  "0NS": "Н.",
  "0GS": "Р.",
  "0DS": "Д.",
  "0AS": "В.",
  "0IS": "Т.",
  "0LS": "М.",
  PAP: "В.",
  PAS: "В.",
  PDP: "Д.",
  PDS: "Д.",
  PGP: "Р.",
  PGS: "Р.",
  PIP: "Т.",
  PIS: "Т.",
  PLP: "М.",
  PLS: "М.",
  PNP: "Н.",

  MAP: "В.",
  MAS: "В.",
  MDP: "Д.",
  MDS: "Д.",
  MGP: "Р.",
  MGS: "Р.",
  MIP: "Т.",
  MIS: "Т.",
  MLP: "М.",
  MLS: "М.",
  MNP: "Н.",

  NAP: "В.",
  NAS: "В.",
  NDP: "Д.",
  NDS: "Д.",
  NGP: "Р.",
  NGS: "Р.",
  NIP: "Т.",
  NIS: "Т.",
  NLP: "М.",
  NLS: "М.",
  NNP: "Н.",
  NNS: "Н.",

  FAP: "В.",
  FAS: "В.",
  FDP: "Д.",
  FDS: "Д.",
  FGP: "Р.",
  FGS: "Р.",
  FIP: "Т.",
  FIS: "Т.",
  FLP: "М.",
  FLS: "М.",
  FNP: "Н.",
  FNS: "Н.",
};

export const SNP1Render = ({ forms }) => {
  const groupped = Object.entries(lodash.groupBy(forms, "tag"));

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px",
        my: 2,
        boxShadow: "none",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.23)",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableCell align="left" sx={{ width: "20px" }}>
            Склон
          </TableCell>
          <TableCell align="left">Форма</TableCell>
        </TableHead>
        <TableBody>
          {groupped.map(([tag, values]) => {
            return (
              <TableRow>
                <TableCell align="left">{SNP1TagMapper[tag]}</TableCell>
                <TableCell align="left">
                  {values.map(({ value, options }) => (
                    <p
                      key={value}
                      style={{ display: "flex", whiteSpace: "break-spaces" }}
                    >
                      {value || "–"}{" "}
                      {options && (
                        <Typography variant="body2" color="info.main">
                          ({AQPTagMapper[options] || options})
                        </Typography>
                      )}
                    </p>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
